import {
    RiHeartPulseLine,
    RiShoppingBagLine
} from '@remixicon/react'
import {motion, useInView, useAnimation} from 'framer-motion';
import React, {useRef, useEffect, useCallback} from 'react';

const SectionHero = () => {
    const ref = useRef(null);
    const isInview = useInView(ref, {once: true});
    const mainControls = useAnimation();

    const handleClickUrlShop = useCallback((param) => {
        window.open(param, '_blank');
    }, [])
  
    useEffect(() => {
      if (isInview) {
        mainControls.start('visible')
      }
    }, [isInview]);

    return (
        <motion.div
            variants={{
                hidden: {opacity: 0, y: 75},
                visible: {opacity: 1, y: 0}
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25
            }}
        >
            <section ref={ref} id="hero" className="py-12 md:pt-12 md:pb-12 overflow-hidden">
                <div className="container max-w-[814px] md:w-[80%] mx-auto px-4 h-full">
                    <div className="flex flex-col-reverse md:flex-row items-center justify-between h-full">
                        <div className="md:w-[80%] text-center md:text-left">
                            <div className='flex items-center bg-white py-[10px] px-[20px] w-max gap-x-2 mb-[26px] mt-[26px] md:mt-[0px] rounded-full mx-auto md:mx-0'>
                                <RiHeartPulseLine 
                                    className='text-xl md:text-2xl text-sky-300'
                                /> 
                                <div className='uppercase text-base font-medium text-[#9ab4b7] tracking-[2.24px]'>
                                    Live your life
                                </div>
                            </div>

                            <h1 className="text-2xl md:text-3xl font-bold mb-4 capitalize">
                                Baju OKA berkualitas premium, affordable & bergaransi
                            </h1>

                            <p className="text-sm mb-[32px] xl:max-w-xl">
                                Baju Perawat / Tenaga Medis yang sering digunakan oleh tenaga medis dalam penanganan pasien di rumah-rumah sakit.
                            </p>

                            {/* <button 
                                className="flex items-center mx-auto md:mx-0 bg-[#8134ce] py-2 px-2 rounded-full text-white w-48"
                                onClick={handleClickUrl}
                            >
                                <RiShoppingBagLine 
                                    size={28}
                                    className='border-r pr-2'
                                /> 
                                <div className='uppercase font-medium cursor-pointer text-sm ml-2 mt-0.5 tracking-[2.24px]'>Beli Sekarang</div>
                            </button> */}

                            <div className='flex justify-center md:justify-normal gap-[14px] text-[30px]'>
                                <div 
                                    className='flex items-center p-[10px] rounded-[10px] shadow-md transition-all cursor-pointer md:w-44'
                                    onClick={() => handleClickUrlShop("https://shopee.co.id/mismardi?categoryId=100011&entryPoint=ShopByPDP&itemId=4742629488&upstream=search")}
                                >
                                    <img className="w-8 border-r-2 pr-1" src="image/shopee.png" alt="..." />

                                    <div className='uppercase font-semibold text-sm  ml-1'>
                                        Beli Sekarang
                                    </div>
                                </div>

                                <div 
                                    className='flex items-center p-[10px] rounded-[10px] shadow-md transition-all cursor-pointer md:w-44'
                                    onClick={() => handleClickUrlShop("https://www.lazada.co.id/sami-shop/?q=All-Products&from=wangpu&langFlag=id&pageTypeId=2")}
                                >
                                    <img className="w-8 border-r-2 pr-1" src="image/lazada.png" alt="..." />

                                    <div className='uppercase font-semibold text-sm ml-1'>
                                        Beli Sekarang
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="md:flex max-w-[814px] md:self-end mb-[24px]">
                            <img className="w-80 xl:w-96" src="image/biru-toska.jpg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    )
}

export default SectionHero;