// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

import {FreeMode, Pagination, Navigation} from 'swiper/modules';

import {motion, useInView, useAnimation} from 'framer-motion';
import React, {useRef, useEffect, useCallback} from 'react';

const SectionLikeThis = () => {
    const ref = useRef(null);
    const isInview = useInView(ref, {once: true});
    const mainControls = useAnimation();

    const handleClickUrlShop = useCallback((param) => {
        window.open(param, "_blank");
    }, [])
  
    useEffect(() => {
      if (isInview) {
        mainControls.start('visible')
      }
    }, [isInview]);

    return (
        <motion.div
            variants={{
                hidden: {opacity: 0, y: 75},
                visible: {opacity: 1, y: 0}
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25
            }}
        >
            <section ref={ref} id='product'>
                <div className="md:my-24 mx-4 md:mx-0 rounded-xl mb-8">
                    <div className="container mx-auto flex flex-col items-center justify-center">
                        <div className='max-w-[500px] mb-8'>
                            <h2 className="text-lg text-center font-semibold uppercase">anda mungkin menyukai ini</h2>
                            <p className="text-md text-center captalize mt-4">
                                pilihan beragam warna dari produk kami dengan penawaran spesial
                            </p>
                        </div>

                        <div className='conatiner mx-auto max-w-[90%] lg:max-w-[80%] overflow-hidden'>
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={15}
                                loop={true}
                                navigation={true}
                                breakpoints={{
                                    340: {
                                        slidesPerView: 1,
                                        spaceBetween: 15,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 15,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 50,
                                    },
                                }}
                                freeMode={true}
                                // pagination={{
                                //     clickable: false
                                // }}
                                modules={[FreeMode, Pagination, Navigation]}
                            >
                                <SwiperSlide>
                                    <div className='h-[300px] border-2 shadow-lg rounded-lg flex flex-col items-center'>
                                        <div className='h-60'>
                                            <img className="w-60" src="image/biru-muda.jpg" alt="..." />
                                        </div>
                                        {/* <button 
                                            className="flex items-center mx-auto md:mt-0 bg-[#8134ce] py-2 px-2 rounded-full text-white w-48"
                                            onClick={handleClickUrl3}
                                        >
                                            <RiShoppingBagLine 
                                                size={28}
                                                className='border-r pr-2'
                                            /> 
                                            <div className='uppercase font-medium cursor-pointer text-sm ml-2 mt-0.5 tracking-[2.24px]'>Beli Sekarang</div>
                                        </button> */}
                                        <div 
                                            className='flex items-center p-[10px] rounded-[10px] shadow-md border-[1px] bg-white transition-all cursor-pointer md:w-44'
                                            onClick={() => handleClickUrlShop("https://shopee.co.id/Setelan-Baju-ok-lengan-pendek-Oka-American-Dril-Pakaian-Kerja-Perawat-Scrub-Dokter-Baju-Perawat-Unisex-i.250184945.4742629488?xptdk=a80efaf3-7b34-4700-bb55-a4509c5fcf82")}
                                        >
                                            <img className="w-8 border-r-2 pr-1" src="image/shopee.png" alt="..." />

                                            <div className='uppercase font-semibold text-sm  ml-1'>
                                                Beli Sekarang
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='h-[300px] border-2 shadow-lg rounded-lg flex flex-col items-center'>
                                        <div className='h-60'>
                                            <img className="w-60" src="image/pink-fanta.jpg" alt="..." />
                                        </div>
                                        {/* <button 
                                            className="flex items-center mx-auto md:mt-0 bg-[#8134ce] py-2 px-2 rounded-full text-white w-48"
                                            onClick={handleClickUrl3}
                                        >
                                            <RiShoppingBagLine 
                                                size={28}
                                                className='border-r pr-2'
                                            /> 
                                            <div className='uppercase font-medium cursor-pointer text-sm ml-2 mt-0.5 tracking-[2.24px]'>Beli Sekarang</div>
                                        </button> */}
                                        <div 
                                            className='flex items-center p-[10px] rounded-[10px] shadow-md bg-white border-[1px] transition-all cursor-pointer md:w-44'
                                            onClick={() => handleClickUrlShop("https://www.lazada.co.id/sami-shop/?q=All-Products&from=wangpu&langFlag=id&pageTypeId=2")}
                                        >
                                            <img className="w-8 border-r-2 pr-1" src="image/lazada.png" alt="..." />

                                            <div className='uppercase font-semibold text-sm ml-1'>
                                                Beli Sekarang
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='h-[300px] border-2 shadow-lg rounded-lg flex flex-col items-center'>
                                        <div className='h-60'>
                                            <img className="w-60" src="image/magenta.jpg" alt="..." />
                                        </div>
                                        {/* <button 
                                            className="flex items-center mx-auto md:mt-0 bg-[#8134ce] py-2 px-2 rounded-full text-white w-48"
                                            onClick={handleClickUrl3}
                                        >
                                            <RiShoppingBagLine 
                                                size={28}
                                                className='border-r pr-2'
                                            /> 
                                            <div className='uppercase font-medium cursor-pointer text-sm ml-2 mt-0.5 tracking-[2.24px]'>Beli Sekarang</div>
                                        </button> */}
                                        <div 
                                            className='flex items-center p-[10px] rounded-[10px] shadow-md border-[1px] bg-white transition-all cursor-pointer md:w-44'
                                            onClick={() => handleClickUrlShop("https://shopee.co.id/SETELAN-BAJU-OKA-SERAGAM-1-SET-BAJU-OK-BAJU-JAGA-BAJU-PERAWAT-OKA-SERAGAM-JAGA-PERAWAT-i.250184945.7362167025?xptdk=1720e1f8-7457-47f7-be11-a1227e18a7b0")}
                                        >
                                            <img className="w-8 border-r-2 pr-1" src="image/shopee.png" alt="..." />

                                            <div className='uppercase font-semibold text-sm  ml-1'>
                                                Beli Sekarang
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='h-[300px] border-2 shadow-lg rounded-lg flex flex-col items-center'>
                                        <div className='h-60'>
                                            <img className="w-60" src="image/kuning-tua.jpg" alt="..." />
                                        </div>
                                        {/* <button 
                                            className="flex items-center mx-auto md:mt-0 bg-[#8134ce] py-2 px-2 rounded-full text-white w-48"
                                            onClick={handleClickUrl3}
                                        >
                                            <RiShoppingBagLine 
                                                size={28}
                                                className='border-r pr-2'
                                            /> 
                                            <div className='uppercase font-medium cursor-pointer text-sm ml-2 mt-0.5 tracking-[2.24px]'>Beli Sekarang</div>
                                        </button> */}
                                        <div 
                                            className='flex items-center p-[10px] rounded-[10px] shadow-md bg-white border-[1px] transition-all cursor-pointer md:w-44'
                                            onClick={() => handleClickUrlShop("https://www.lazada.co.id/sami-shop/?q=All-Products&from=wangpu&langFlag=id&pageTypeId=2")}
                                        >
                                            <img className="w-8 border-r-2 pr-1" src="image/lazada.png" alt="..." />

                                            <div className='uppercase font-semibold text-sm ml-1'>
                                                Beli Sekarang
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='h-[300px] border-2 shadow-lg rounded-lg flex flex-col items-center'>
                                        <div className='h-60'>
                                            <img className="w-60" src="image/mustard.jpg" alt="..." />
                                        </div>
                                        {/* <button 
                                            className="flex items-center mx-auto md:mt-0 bg-[#8134ce] py-2 px-2 rounded-full text-white w-48"
                                            onClick={handleClickUrl3}
                                        >
                                            <RiShoppingBagLine 
                                                size={28}
                                                className='border-r pr-2'
                                            /> 
                                            <div className='uppercase font-medium cursor-pointer text-sm ml-2 mt-0.5 tracking-[2.24px]'>Beli Sekarang</div>
                                        </button> */}
                                        <div 
                                            className='flex items-center p-[10px] rounded-[10px] shadow-md border-[1px] bg-white transition-all cursor-pointer md:w-44'
                                            onClick={() => handleClickUrlShop("https://shopee.co.id/Setelan-Baju-ok-lengan-pendek-Unisex-American-Drill-Baju-Jaga-Baju-Dinas-Baju-ok-terbaru-Baju-Ok-Murah-Baju-Ok-Termurah-Baju-Ok-Terlaris-Scrub-Medis-Scrubs-Medical-Doctors-Scrub-Baju-Perawat-Scrubs-Medis-i.250184945.7059917754?sp_atk=aabcf30e-35c6-413f-afd9-bb7a7982e95e&xptdk=aabcf30e-35c6-413f-afd9-bb7a7982e95e")}
                                        >
                                            <img className="w-8 border-r-2 pr-1" src="image/shopee.png" alt="..." />

                                            <div className='uppercase font-semibold text-sm  ml-1'>
                                                Beli Sekarang
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='h-[300px] border-2 shadow-lg rounded-lg flex flex-col items-center'>
                                        <div className='h-60'>
                                            <img className="w-60" src="image/hijau-daun.jpg" alt="..." />
                                        </div>
                                        {/* <button 
                                            className="flex items-center mx-auto md:mt-0 bg-[#8134ce] py-2 px-2 rounded-full text-white w-48"
                                            onClick={handleClickUrl3}
                                        >
                                            <RiShoppingBagLine 
                                                size={28}
                                                className='border-r pr-2'
                                            /> 
                                            <div className='uppercase font-medium cursor-pointer text-sm ml-2 mt-0.5 tracking-[2.24px]'>Beli Sekarang</div>
                                        </button> */}
                                        <div 
                                            className='flex items-center p-[10px] rounded-[10px] shadow-md bg-white border-[1px] transition-all cursor-pointer md:w-44'
                                            onClick={() => handleClickUrlShop("https://www.lazada.co.id/sami-shop/?q=All-Products&from=wangpu&langFlag=id&pageTypeId=2")}
                                        >
                                            <img className="w-8 border-r-2 pr-1" src="image/lazada.png" alt="..." />

                                            <div className='uppercase font-semibold text-sm ml-1'>
                                                Beli Sekarang
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    )
}

export default SectionLikeThis;