import { useCallback, useEffect, useState } from "react";
import Hamburger from "hamburger-react";
import {Link} from 'react-scroll';
import {AnimatePresence, motion} from 'framer-motion';

const Navbars = () => {
    const [topHide, setTopHide] = useState(null);
    const [isOpen, setOpen] = useState(false);
    const [isScrollMenu, setScrollMenu] = useState(false);

    const handleClickHideShowMenu = useCallback(() => {
        setTopHide(!isOpen)
    }, [isOpen]);

    const resizeMenu = useCallback(() => {
        const witdhs = window.innerWidth;

        if (witdhs > 1024) {
            setTopHide(true)
            setOpen(false)
        } else {
            setTopHide(false)
        }
    }, [window]);

    const scrolHeight = useCallback(() => {
        if (window.scrollY >= 73) {
            setScrollMenu(true);
            setTopHide(false)
            setOpen(false)
        } else {
            setScrollMenu(false);
            if (window.innerWidth > 1024) setTopHide(true);
        }
    }, [window])

    useEffect(() => {
        if (topHide == null && window.innerWidth > 1024) setTopHide(true);
        
        window.addEventListener('resize', resizeMenu);
        window.addEventListener('scroll', scrolHeight);

        return () => {
            window.removeEventListener('resize', resizeMenu);
            window.removeEventListener('scroll', scrolHeight);
        }
    }, [window])

    return (
        <header id="home" className="bg-white py-2 xl:pt-2 xl:pb-2">
            <AnimatePresence>
                <div className='container mx-auto xl:relative flex flex-col xl:flex-row xl:justify-between gap-y-4 xl:gap-y-8'>
                    {isScrollMenu ? (
                        <NavbarScroll 
                            isScrollMenu={isScrollMenu}
                        />
                    ) : (
                        <NavbarNonFix 
                            isOpen={isOpen}
                            setOpen={setOpen}
                            topHide={topHide}
                            toggleHandle={handleClickHideShowMenu}
                        />
                    )}
                </div>
            </AnimatePresence>
        </header>
    )
}

const NavbarNonFix = ({isOpen, setOpen, topHide, toggleHandle}) => {
    return (
        <>
            <div className='flex justify-between xl:justify-normal pl-5 xl:pl-0 z-20'>
                <img className="w-16" src="image/logo-adam.png" alt="..." />

                {/* button burger */}
                <div
                    className={'xl:hidden pr-2 pt-2 xl:pt-0 xl:pr-0'}
                >
                    <Hamburger 
                        size={25}
                        toggled={isOpen}
                        toggle={setOpen}
                        onToggle={toggleHandle}
                    />
                </div>
            </div>
            
            {/* list menu */}
            <div className={`absolute xl:static bg-white xl:min-h-fit left-0 xl:top-0 xl:w-auto w-full flex items-center px-5 xl:px-0 ${topHide ? 'opacity-100 transition-all duration-700' : 'opacity-0'}`}>
                <ul className="flex flex-col xl:flex-row xl:items-center gap-4 mt-16 md:mt-24 xl:mt-5 mb-5 xl:mb-0">
                    <li>
                        <Link to="quality" spy={true} smooth={true} offset={-20} duration={500} className="hover:text-cyan-500" href="#!">Kualitas</Link>
                    </li>
                    <li className="hidden md:block">
                        <Link to="statistick" spy={true} smooth={true} offset={-100} duration={500} className="hover:text-cyan-500" href="#!">Statistick</Link>
                    </li>
                    <li>
                        <Link to="reviews" spy={true} smooth={true} offset={310} duration={500} className="hover:text-cyan-500" href="#!">Ulasan</Link>
                    </li>
                    <li>
                        <Link to="product" spy={true} smooth={true} offset={-100} duration={500} className="hover:text-cyan-500" href="#!">Produk</Link>
                    </li>
                    <li>
                        <Link to="faq" spy={true} smooth={true} offset={-100} duration={500} className="hover:text-cyan-500" href="#!">Pertanyaan</Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

const NavbarScroll = ({isScrollMenu, }) => {
    return (
        <motion.div
            key={1}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={NavAnimation}
            className="fixed z-20 bg-white w-full md:w-auto rounded-lg left-1/2 border-[1px] shadow-md"
        >
            <nav className='flex items-center justify-between px-4 py-3 mx-auto'>
                <Link to="hero" spy={true} smooth={true} offset={-150} duration={500} className="hover:text-cyan-500 mr-3 md:mr-5" href="#!">
                    <img className="w-10 rounded-full border-[1px] shadow-md" src="image/logo-adam.png" alt="..." />
                </Link>

                <div className="block xl:hidden">
                    <ul className="flex flex-row lg:items-center gap-4 mt-4 md:mt-5 lg:mt-0 mb-5 lg:mb-0 mr-3">
                        <li>
                            <Link to="quality" spy={true} smooth={true} offset={-100} duration={500} className="hover:text-cyan-500" href="#!">Kualitas</Link>
                        </li>
                        <li>
                            <Link to="reviews" spy={true} smooth={true} offset={500} duration={500} className="hover:text-cyan-500" href="#!">Ulasan</Link>
                        </li>
                        <li>
                            <Link to="product" spy={true} smooth={true} offset={-100} duration={500} className="hover:text-cyan-500" href="#!">Produk</Link>
                        </li>
                        <li>
                            <Link to="faq" spy={true} smooth={true} offset={-60} duration={500} className="hover:text-cyan-500" href="#!">Pertanyaan</Link>
                        </li>
                    </ul>
                </div>

                <div className="hidden xl:block">
                    <ul className="flex flex-col lg:flex-row lg:items-center gap-4 mt-16 md:mt-5 lg:mt-0 mb-5 lg:mb-0">
                        <li>
                            <Link to="quality" spy={true} smooth={true} offset={-20} duration={500} className="hover:text-cyan-500" href="#!">Kualitas</Link>
                        </li>
                        <li>
                            <Link to="statistick" spy={true} smooth={true} offset={-100} duration={500} className="hover:text-cyan-500" href="#!">Statistick</Link>
                        </li>
                        <li>
                            <Link to="reviews" spy={true} smooth={true} offset={310} duration={500} className="hover:text-cyan-500" href="#!">Ulasan</Link>
                        </li>
                        <li>
                            <Link to="product" spy={true} smooth={true} offset={-100} duration={500} className="hover:text-cyan-500" href="#!">Produk</Link>
                        </li>
                        <li>
                            <Link to="faq" spy={true} smooth={true} offset={-100} duration={500} className="hover:text-cyan-500" href="#!">Pertanyaan</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </motion.div>
    )
}

const NavAnimation = {
    initial: {
        y: -50,
        x: "-50%",
        opacity: 0
    },
    animate: {
        y: 0,
        x: "-50%",
        opacity: 1,
        transition: {
            type: "spring",
            damping: 10,
            stiffness: 100
        }
    },
    exit: {
        y: -50,
        opacity: 0
    }
}

export default Navbars;