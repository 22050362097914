import { useState } from 'react';
import Faq from '../etc/Faq'

import {motion, useInView, useAnimation} from 'framer-motion';
import React, {useRef, useEffect} from 'react';

const SectionFaq = () => {
    const ref = useRef(null);
    const isInview = useInView(ref, {once: true});
    const mainControls = useAnimation();

    useEffect(() => {
      if (isInview) {
        mainControls.start('visible')
      }
    }, [isInview]);
    
    const [faqs, setFaqs] = useState([
        {
          question: "mengapa harus percaya dan membeli di adam t-shirt ?",
          answer: "Kami Mengutamakan Kualitas Pakaian dan Pelanggan, menurut kami pelanggan adalah satu-satunya tujuan kami untuk bisa mendapatkan perhatian yang lebih baik maka kami sebagai penjual harus memperhatikan kualitas produksi.",
          call: "Kepuasan pelanggan adalah komitmen kami.",
          open: false
        },
        {
          question: "apakah bisa menjadi mitra bisnis adam t-shirt ?",
          answer: "Ya tentu saja. Kami membuka peluang untuk anda yang ingin menjadi mitra bisnis dari adam t-shirt.",
          call: "Silahkan menghubungi kami di nomor whatsapp dibawah.",
          open: false
        },
        {
          question: "apakah bisa membeli secara banyak (grosir) di adam t-shirt ?",
          answer: "Ya tentu bisa! Kami menyediakan paket juga untuk bisa dipesan oleh pelanggan kami.",
          call: "Untuk lebih detail silahkan menghubungi nomor whatsapp dibawah.",
          open: false
        },
        {
          question: "Apakah bisa memesan baju OKA secara khusus (customize) ?",
          answer: "Untuk customize silahkan menghubungi nomor whatsap dibawah,",
          call: "atau chat dengan menekan tombol whatsapp disamping.",
          open: false
        }
    ]);

    const toggleFAQ = index => {
        setFaqs(
          faqs.map((faq, i) => {
            if (i === index) {
              faq.open = !faq.open;
            } else {
              faq.open = false;
            }
    
            return faq;
          })
        );
    };

    return (
      <motion.div
          variants={{
              hidden: {opacity: 0, y: 75},
              visible: {opacity: 1, y: 0}
          }}
          initial="hidden"
          animate={mainControls}
          transition={{
            duration: 0.5,
            delay: 0.25
          }}
      >
        <section ref={ref} id='faq'>
          <div className="container mx-auto flex flex-col items-center justify-center">
              <div className='max-w-5xl mx-auto md:mb-[50px] mb-[15px]'>
                <h2 className="text-lg text-center font-semibold uppercase mt-[50px] md:mt-0">
                  sering ditanyakan
                </h2>
              </div>

              <div className='md:max-w-5xl md:mx-auto'>
                  {faqs.map((faq, index) => (
                      <Faq 
                          faq={faq} 
                          index={index} 
                          key={index} 
                          toggleFAQ={toggleFAQ} 
                      />
                  ))}
              </div>
          </div>
        </section>
      </motion.div>
    )
}

export default SectionFaq;