import {
    RiChat1Line,
    RiWhatsappLine,
    RiMailLine
} from '@remixicon/react';
import React, {useCallback} from 'react'

const SectionFloating = () => {
    const handleClickChat = useCallback(() => {
        window.open("https://wa.wizard.id/f8a85b", "_blank");
    }, []);

    return (
        <div 
            className='group fixed bottom-10 right-3 p-2 flex items-end justify-end w-24 h-24 cursor-pointer'
            onClick={handleClickChat}
        >
            <div className='text-white shadow-xl flex items-center justify-center p-3 rounded-full border-2 bg-gradient-to-tr from-green-300 to-green-500 z-50 absolute'>
                <RiWhatsappLine 
                    className='h-10 w-10 transition-all duration-[0.6s]'
                />
            </div>
        </div>
    )
}

export default SectionFloating;