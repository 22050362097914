import SectionFaq from "./components/contents/SectionFaq";
import SectionFloating from "./components/contents/SectionFloating";
import SectionFooter from "./components/contents/SectionFooter";
import SectionHero from "./components/contents/SectionHero";
import SectionLikeThis from "./components/contents/SectionLikeThis";
import SectionServices from "./components/contents/SectionServices";
import SectionStatistik from "./components/contents/SectionStatistik";
import SectionTestimoni from "./components/contents/SectionTestimoni";
import Navbars from "./components/headers/Navbar";

function App() {
  
  return (
    <div>
      <Navbars />
      <SectionHero />
      <SectionServices />
      <SectionStatistik />
      <SectionTestimoni />
      <SectionLikeThis />
      <SectionFaq />
      <SectionFooter />
      <SectionFloating />
    </div>
  );
}

export default App;
