import {
    RiDoubleQuotesL,
    RiShoppingBagLine
} from '@remixicon/react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import {motion, useInView, useAnimation} from 'framer-motion';
import React, {useRef, useEffect, useCallback} from 'react';

import {FreeMode, Pagination, Autoplay} from 'swiper/modules';

const SectionTestimoni = () => {
    const ref = useRef(null);
    const isInview = useInView(ref, {once: true});
    const mainControls = useAnimation();

    const handleClickUrlShop = useCallback((param) => {
        window.open(param, '_blank');
    }, [])
  
    useEffect(() => {
      if (isInview) {
        mainControls.start('visible')
      }
    }, [isInview]);

    return (
        <motion.div
            variants={{
                hidden: {opacity: 0, y: 75},
                visible: {opacity: 1, y: 0}
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25
            }}
        >
            <section ref={ref} id='reviews'>
                <div className="bg-blue-200 bg-cover bg-no-repeat rounded-[20px] max-w-[1466px] h-[585px] mx-4 xl:mx-auto xl:pt-[70px] px-6 xl:px-0 relative flex items-center xl:items-start">
                    <div className='conatiner mx-auto md:w-[90%]'>
                        <div className='flex flex-col md:flex-row items-center justify-center'>
                            <div className="md:flex max-w-[814px] -mt-3 mb-[24px]">
                                <img className="w-80" src="image/hijau-toska.png" alt="..." />
                            </div>

                            <div className='md:w-[48%] md:ml-16 text-center md:text-left xl:-mt-10'>
                                <h1 className="capitalize text-2xl text-center md:text-left md:text-3xl font-bold mb-4">
                                    dapatkan pengalaman terbaik <br /> dari baju OKA
                                </h1>

                                <p className="text-sm text-center md:text-left mb-[32px] xl:max-w-xl">
                                    Baju dinas perawat / Tenaga medis yang digunakan untuk menangani <br /> pasien di rumah sakit.
                                    {/* Baju Perawat / Tenaga Medis yang sering digunakan oleh tenaga medis dalam penanganan pasien di rumah-rumah sakit. */}
                                </p>

                                {/* <button 
                                    className="flex items-center mx-auto md:mx-0 bg-[#8134ce] py-2 px-2 rounded-full text-white w-48 cursor-pointer"
                                    onClick={handleClickUrl2}
                                >
                                    <RiShoppingBagLine 
                                        size={28}
                                        className='border-r pr-2'
                                    /> 
                                    <div className='uppercase font-medium text-sm ml-2 mt-0.5 tracking-[2.24px]'>Beli Sekarang</div>
                                </button> */}
                                <div className='flex justify-center md:justify-normal gap-[14px] text-[30px]'>
                                    <div 
                                        className='flex items-center p-[10px] rounded-[10px] shadow-md bg-white transition-all cursor-pointer md:w-44'
                                        onClick={() => handleClickUrlShop("https://shopee.co.id/mismardi?categoryId=100011&entryPoint=ShopByPDP&itemId=4742629488&upstream=search")}
                                    >
                                        <img className="w-8 border-r-2 pr-1" src="image/shopee.png" alt="..." />

                                        <div className='uppercase font-semibold text-sm md:text-center xl:text-nowrap ml-1'>
                                            Beli Sekarang
                                        </div>
                                    </div>

                                    <div 
                                        className='flex items-center p-[10px] rounded-[10px] shadow-md bg-white transition-all cursor-pointer md:w-44'
                                        onClick={() => handleClickUrlShop("https://www.lazada.co.id/sami-shop/?q=All-Products&from=wangpu&langFlag=id&pageTypeId=2")}
                                    >
                                        <img className="w-8 border-r-2 pr-1" src="image/lazada.png" alt="..." />

                                        <div className='uppercase font-semibold text-sm md:text-center xl:text-nowrap ml-1'>
                                            Beli Sekarang
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mx-auto mt-8 md:mt-24 xl:-mt-[144px] bg-white rounded-lg relative mb-2"> 
                    <div className='flex flex-col md:flex-row items-center justify-center'>
                        <h2 className="capitalize font-semibold text-center md:text-left text-2xl mx-4 max-w-[300px]">
                            ulasan positif <br /> pelanggan kami
                        </h2>
                        {/* helpful positive reviews */}

                        <div className="text-center md:text-left max-w-[410px] mt-20 md:mt-12 mb-12 md:border-l-2">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={15}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: true,
                                }}
                                breakpoints={{
                                    340: {
                                        slidesPerView: 1,
                                        spaceBetween: 15,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                        spaceBetween: 15,
                                    },
                                    1024: {
                                        slidesPerView: 1,
                                        spaceBetween: 15,
                                    },
                                }}
                                freeMode={true}
                                // pagination={{
                                //     clickable: false
                                // }}
                                modules={[FreeMode, Pagination, Autoplay]}
                            >
                                <SwiperSlide>
                                    <p 
                                        className="font-light relative text-md leading-[190%] text-center xl:text-left px-12"
                                    >
                                        <span className="">
                                            Ini order yg kedua kalinya. Jahitannya rapi, ukurannya pas, bahannya sesuai deskripsi. 
                                            Seller responnya cpt bgt. Kemarin checkout hari ini dah nyampe. Thanks seller
                                        </span>
                                    </p>
                                    <div className="capitalize text-sm text-[#4c5354] font-semibold tracking-[2.24px] px-12 pt-4">
                                        shopee customer
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <p 
                                        className="font-light relative text-md leading-[190%] text-center xl:text-left px-12"
                                    >
                                        <span className="">
                                            Terimakasih barang sudah samapai barangnya bagus banget...kainnya adem lembut pokoknya the best biar bintang yang berbicara⭐⭐⭐⭐⭐
                                        </span>
                                    </p>
                                    <div className="capitalize text-sm text-[#4c5354] font-semibold tracking-[2.24px] px-12 pt-4">
                                        shopee customer
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <p 
                                        className="font-light relative text-md leading-[190%] text-center xl:text-left px-12"
                                    >
                                        <span className="">
                                            Paket mendarat dengan selamat.. Baju ny bagus, jahitan ny rapi.. Bahan ny juga tebal dengan harga yg ga terlalu mahal... Ga kecewa pesan baju d sni.. Lain waktu bakalan order lagi.. Mkasih juga buat abg kurir ny yg udah nganter paket smpek t4 tujuan..
                                        </span>
                                    </p>
                                    <div className="capitalize text-sm text-[#4c5354] font-semibold tracking-[2.24px] px-12 pt-4">
                                        shopee customer
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>

                    <div className="absolute top-20 md:-top-6 left-0 w-full flex items-center justify-center">
                        <div className="text-2xl text-white bg-[#0e9eff] p-1 rounded-md">
                            <RiDoubleQuotesL 
                                size={36}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    )
}

export default SectionTestimoni;