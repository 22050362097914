import {
    RiMapPinLine,
    RiMailLine,
    RiWhatsappLine,
    RiInstagramLine,
    RiFacebookLine,
    RiTiktokLine,
    RiHeartFill
} from '@remixicon/react'
import {motion, useInView, useAnimation} from 'framer-motion';
import React, {useRef, useEffect, useCallback} from 'react';
import {Link} from 'react-scroll'

const SectionFooter = () => {
    const ref = useRef(null);
    const isInview = useInView(ref, {once: true});
    const mainControls = useAnimation();

    const handleClickUrlShop = useCallback((param) => {
        window.open(param, "_blank")
    })
  
    useEffect(() => {
      if (isInview) {
        mainControls.start('visible')
      }
    }, [isInview]);

    return (
        <motion.div
            variants={{
                hidden: {opacity: 0, y: 75},
                visible: {opacity: 1, y: 0}
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25
            }}
        >
            <footer ref={ref} className="pt-8 xl:pt-[150px] mx-4">
                <div className="container mx-auto pb-12 xl:pb-[100px]">
                    <div className="flex flex-col md:flex-row justify-between gap-x-8 gap-y-10">
                        <div className="flex-1 items-center">
                            <a href="" className="mb-[30px]">
                                <img className="w-16" src="image/logo-adam.webp" alt="..." />
                            </a>
                            <p className="mb-[20px] text-justify">
                                Sebagai Pusat Baju OK atau Perawat Online di Jakarta, 
                                kami menciptakan kemungkinan-kemungkinan gaya tanpa batas dan mejaga anda dalam keadaan steril dengan cara memperluas jangkauan produk kami, 
                                Kami menjadikan Anda sebagai pusatnya. <p className='font-semibold'>Bersama ADAM T-SHIRT, You Own Now.</p>
                            </p>

                            <div className='flex flex-col gap-y-3'>
                                <div className="flex items-center gap-x-[6px]">
                                    <RiMapPinLine 
                                        className='text-cyan-500'
                                    />
                                    <div>
                                        Batu Ampar, Jakarta Timur
                                    </div>
                                </div>

                                <div className="flex items-center gap-x-[6px]">
                                    <RiMailLine 
                                        className='text-cyan-500'
                                    />
                                    <div>
                                        halo@adamtshirt.com
                                    </div>
                                </div>

                                <div className="flex items-center gap-x-[6px]">
                                    <RiWhatsappLine 
                                        className='text-cyan-500'
                                    />
                                    <div>
                                        0813-1727-7335
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-col my-8'>
                                <div>
                                    <h4 className='text-md mb-3 font-semibold uppercase'>Temukan Kami</h4>
                                </div>
                                
                                <div className='flex gap-[14px] text-[30px]'>
                                    <div 
                                        className='p-[10px] rounded-[10px] shadow-md text-cyan-400 hover:text-cyan-500 transition-all cursor-pointer'
                                        onClick={() => handleClickUrlShop("https://shopee.co.id/mismardi?categoryId=100011&entryPoint=ShopByPDP&itemId=4742629488&upstream=search")}
                                    >
                                        <img className="w-8" src="image/shopee.png" alt="..." />
                                    </div>

                                    <div 
                                        className='p-[10px] rounded-[10px] shadow-md text-cyan-400 hover:text-cyan-500 transition-all cursor-pointer'
                                        onClick={() => handleClickUrlShop("https://www.lazada.co.id/sami-shop/?q=All-Products&from=wangpu&langFlag=id&pageTypeId=2")}
                                    >
                                        <img className="w-8" src="image/lazada.png" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex-1 items-center -mt-8 md:mt-5">
                            <h4 className='text-xl mb-5 font-semibold'>Link Cepat</h4>
                            <div className='flex'>
                                <ul className='flex-1 flex flex-col gap-y-5'>
                                    <li>
                                        <Link to="hero" spy={true} smooth={true} offset={-100} duration={500} className="hover:text-cyan-500" href="#!">Beranda</Link>
                                    </li>
                                    <li>
                                        <Link to="quality" spy={true} smooth={true} offset={50} duration={500} className="hover:text-cyan-500" href="#!">Kualitas Pakaian</Link>
                                    </li>
                                    <li className='hidden md:block'>
                                        <Link to="statistick" spy={true} smooth={true} offset={-20} duration={500} className="hover:text-cyan-500" href="#!">Statistick</Link>
                                    </li>
                                    <li className='block md:hidden'>
                                        <Link to="reviews" spy={true} smooth={true} offset={-10} duration={500} className="hover:text-cyan-500" href="#!">Ulasan Pelanggan</Link>
                                    </li>
                                </ul>

                                <ul className='flex-1 flex flex-col gap-y-5'>
                                    <li className='hidden md:block'>
                                        <Link to="reviews" spy={true} smooth={true} offset={-10} duration={500} className="hover:text-cyan-500" href="#!">Ulasan Pelanggan</Link>
                                    </li>
                                    <li>
                                        <Link to="product" spy={true} smooth={true} offset={-20} duration={500} className="hover:text-cyan-500" href="#!">Produk Kami</Link>
                                    </li>
                                    <li>
                                        <Link to="faq" spy={true} smooth={true} offset={-50} duration={500} className="hover:text-cyan-500" href="#!">Pertanyaan</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='flex-1 items-center md:mt-5'>
                            <h4 className='text-xl mb-5 font-semibold'>Jam Operasional</h4>
                            <div className='flex flex-col gap-y-3 md:gap-y-5'>
                                <div className='flex-1'>
                                    <div className='flex justify-between items-center border-b pb-[10px]'>
                                        <div>Senin - Jumat</div>
                                        <div className='text-cyan-500 font-medium'>08.00 - 15.00</div>
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div className='flex justify-between items-center border-b pb-[10px]'>
                                        <div>Sabtu</div>
                                        <div className='text-cyan-500 font-medium'>09.00 - 15.00</div>
                                    </div>
                                </div>
                                <div className='flex-1'>
                                    <div className='flex justify-between items-center border-b pb-[10px]'>
                                        <div>Minggu</div>
                                        <div className='text-cyan-500 font-medium'>Sent the next day</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='py-[30px] md:border-t'>
                    <div className='container mx-auto text-center'>
                        <div className='flex flex-col md:flex-row items-center justify-center gap-1 text-sm'>
                            <div className='font-light'>&copy; 2024</div>
                            <div className='font-light'>afp collaboration adam tshirt and made With</div>
                            <RiHeartFill 
                                size={20}
                                className='font-light text-red-500'
                            />
                        </div>
                    </div>
                </div>
            </footer>
        </motion.div>
    )
}

export default SectionFooter;