import {motion, useInView, useAnimation} from 'framer-motion';
import React, {useRef, useEffect} from 'react';

const SectionStatistik = () => {
    const ref = useRef(null);
    const isInview = useInView(ref, {once: true});
    const mainControls = useAnimation();
  
    useEffect(() => {
      if (isInview) {
        mainControls.start('visible')
      }
    }, [isInview]);

    return (
        <motion.div
            variants={{
                hidden: {opacity: 0, y: 75},
                visible: {opacity: 1, y: 0}
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25
            }}
        >
            <section ref={ref} id="statistick" className="hidden md:block">
                <div className="container mx-auto my-8">
                    <div className="mb-10">
                        <h2 className="text-lg text-center font-semibold uppercase">statistik menunjukan</h2>
                    </div>

                    <div className="flex flex-col md:flex-row gap-y-6 justify-between">
                        <div className="flex-1 flex flex-col items-center">
                            <div className="text-4xl md:text-[30px] font-semibold text-cyan-300 xl:mb-2">+10.000</div>
                            <div className="">produk telah terjual</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center">
                            <div className="text-4xl md:text-[30px] font-semibold text-cyan-300 xl:mb-2">+3.800</div>
                            <div className="">pengikut shopee</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center">
                            <div className="text-4xl md:text-[30px] font-semibold text-cyan-300 xl:mb-2">+13.200</div>
                            <div className="">ulasan pelanggan</div>
                        </div>

                        <div className="flex-1 flex flex-col items-center">
                            <div className="text-4xl md:text-[30px] font-semibold text-cyan-300 xl:mb-2">+5.000</div>
                            <div className="">pelanggan senang</div>
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    )
}

export default SectionStatistik;