import {
    RiShirtLine,
    RiRulerLine,
    RiVerifiedBadgeLine,
    RiGenderlessLine,
    RiColorFilterLine,
    RiPriceTag3Line
} from '@remixicon/react'
import {motion, useInView, useAnimation} from 'framer-motion';
import React, {useRef, useEffect} from 'react';

const SectionServices = () => {
    const ref = useRef(null);
    const isInview = useInView(ref, {once: true});
    const mainControls = useAnimation();
  
    useEffect(() => {
      if (isInview) {
        mainControls.start('visible')
      }
    }, [isInview]);

    return (
        <motion.div
            variants={{
                hidden: {opacity: 0, y: 75},
                visible: {opacity: 1, y: 0}
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25
            }}
        >
            <section ref={ref} id='quality'>
                <div className='mt-6 md:mt-0 md:bg-blue-200 bg-cover bg-no-repeat rounded-[20px] max-w-[1466px] mx-4 xl:mx-auto xl:pt-[70px] px-6 xl:px-0 relative md:h-[368px] flex items-center xl:items-start -z-10'>
                    <div className="container mx-auto">
                        <div className="flex flex-col items-center md:mb-[60px]">
                            <h2 className="capitalize text-2xl md:text-3xl font-semibold mb-4 text-center max-w-[468px]">
                                Produk Detail
                            </h2>
                            <p className="hidden md:block text-md text-center max-w-2xl md:max-w-[568px]">
                                Dari Baju Perawat / Tenaga Medis
                            </p>
                        </div>
                    </div>
                </div>
                
                <div className="container md:w-[90%] mx-auto md:-mt-[144px] bg-white rounded-lg">
                    <div className='flex flex-col md:flex-row items-center justify-center'>
                        <div>
                            {/* section icon left */}
                            <div className="p-[30px] rounded-[10px] min-h-[100px] flex flex-col items-center text-center">
                                <div className='md:flex md:flex-row-reverse md:self-end'>
                                    <div className="mb-[15px] flex items-center justify-center">
                                        <RiShirtLine 
                                            size={26}
                                        />
                                    </div>
                                    
                                    <h3 className='text-xl font-bold mb-[10px] md:-mt-0.5'>
                                        American Drill
                                    </h3>
                                </div>

                                <p className='text-sm font-light leadinng-normal max-w-[300px] md:text-right'>
                                    Bahan kain dari katun ataupun polyester. Bahan ini bersifat tebal dan dingin ketika digunakan, 
                                    sehingga dapat menyerap keringat dengan baik dan nyaman untuk digunakan.
                                    {/* Kain ini dapat menyerap keringat dengan baik sehingga nyaman untuk digunakan. */}
                                </p>
                            </div>

                            <div className="p-[30px] rounded-[10px] min-h-[100px] flex flex-col items-center text-center">
                                <div className='md:flex md:flex-row-reverse md:self-end'>
                                    <div className="mb-[15px] flex items-center justify-center">
                                        <RiRulerLine 
                                            size={26}
                                        />
                                    </div>

                                    <h3 className='text-xl font-bold mb-[10px] md:-mt-0.5'>
                                        Semua Ukuran
                                    </h3>
                                </div>

                                <p className='text-sm font-light leadinng-normal max-w-[300px] md:text-right'>
                                    rata-rata ukuran baju yang sudah ada, yaitu S (small), M (medium), L (large), XL (extra large), Hinggal XXL (extra extra large).
                                </p>
                            </div>

                            <div className="p-[30px] rounded-[10px] min-h-[100px] flex flex-col items-center text-center">
                                <div className='md:flex md:flex-row-reverse md:self-end'>
                                    <div className="mb-[15px] flex items-center justify-center">
                                        <RiVerifiedBadgeLine 
                                            size={26}
                                        />
                                    </div>

                                    <h3 className='text-xl font-bold mb-[10px] md:-mt-0.5'>
                                        Kualitas Bagus
                                    </h3>
                                </div>

                                <p className='text-sm font-light leadinng-normal max-w-[300px] md:text-right'>
                                    Kami hanya menyediakan produk dengan baha berkualitas dan bergaransi untuk kepuasan pelanggan!
                                </p>
                            </div>
                        </div>

                        {/* image */}
                        <div className="hidden md:block">
                            <img className="w-80" src="image/dusty-pink.jpg" alt="..." />
                        </div>

                        {/* section icon right */}
                        <div>
                            <div className="p-[30px] rounded-[10px] min-h-[100px] flex flex-col items-center text-center">
                                <div className='md:flex md:flex-row md:self-start'>
                                    <div className="mb-[15px] flex items-center justify-center">
                                        <RiGenderlessLine 
                                            size={26}
                                        />
                                    </div>

                                    <h3 className='text-xl font-bold mb-[10px]'>
                                        Pria dan Wanita
                                    </h3>
                                </div>

                                <p className='text-sm font-light leadinng-normal max-w-[300px] md:text-left'>
                                    Menggambarkan sebuah produk yang tidak mementingkan gender, 
                                    sehingga bisa digunakan oleh pria maupun wanita dan tetap nyaman digunakan. 
                                </p>
                            </div>

                            <div className="p-[30px] rounded-[10px] min-h-[100px] flex flex-col items-center text-center">
                                <div className='md:flex md:flex-row md:self-start'>
                                    <div className="mb-[15px] flex items-center justify-center">
                                        <RiColorFilterLine 
                                            size={26}
                                        />
                                    </div>

                                    <h3 className='text-xl font-bold mb-[10px] md:-mt-0.5'>
                                        Banyak Pilihan Warna
                                    </h3>
                                </div>

                                <p className='text-sm font-light leadinng-normal max-w-[300px] md:text-left'>
                                    Cream, Dusty Pink, Hijau Army, Olive, Hijau Lemon, Hijau Mind, Kuning Gold, Navy, Ungu Muda, Merah Marun
                                </p>
                            </div>

                            <div className="p-[30px] rounded-[10px] min-h-[100px] flex flex-col items-center text-center">
                                <div className='md:flex md:flex-row md:self-start'>
                                    <div className="mb-[15px] flex items-center justify-center">
                                        <RiPriceTag3Line 
                                            size={26}
                                        />
                                    </div>

                                    <h3 className='text-xl font-bold mb-[10px] md:-mt-0.5'>
                                        Harga Tebaik
                                    </h3>
                                </div>

                                <p className='text-sm font-light leadinng-normal max-w-[300px] md:text-left'>
                                    Bergabunglah dengan ribuan pelanggan adam t-shirt untuk bandingkan harga dan temukan penawaran terbaik dari toko kami.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
    );
}

export default SectionServices;