import {
    RiAddLine,
    RiSubtractLine
} from '@remixicon/react';

const Faq = ({ faq, index, toggleFAQ }) => {
    return (
        <div 
            className='pt-7 pb-4 px-[30px] border-b cursor-pointer select-none'
            key={index}
            onClick={() => toggleFAQ(index)}
        >
            <div className='flex flex-row items-center justify-between mb-2'>
                <h4 className="text-lg capitalize">{faq.question}</h4>
                {!faq.open ? (
                    <RiAddLine 
                        size={20}
                        className='text-cyan-500'
                    />
                ) : (
                    <RiSubtractLine 
                        size={20}
                        className='text-cyan-500'
                    />
                )}
            </div>

            <div className={`transition-all ${faq.open ? 'duration-300 ease-in h-50 md:h-[80px] overflow-auto' : 'duration-300 ease-out h-0 overflow-hidden'}`}>
                <p className='font-light'>
                    {faq.answer}
                </p>

                <p className='font-light'>
                    {faq.call}
                </p>
            </div>
        </div>
    );
};
  
export default Faq;